import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';

import { EmailLoader } from './shared/loaders';

const GetInTouch = () => {
  const emailRegExp =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/;
  const validationSchema = yup.object().shape({
    // contactName: yup.string().required('Please provide your name'),
    contactEmail: yup
      .string()
      .required('Please provide your email address')
      .matches(emailRegExp, 'Please enter a valid email address'),
    // contactTopic: yup.string().min(1, 'Please select a message topic'),
    contactMessage: yup.string().min(1, 'Please enter a comment'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const successMsg = {
    heading: 'Success!',
    message: "Thank you. We've got your details and will be in touch.",
  };

  const [submitting, setSubmitting] = useState(false);
  const [formSuccess, showSuccess] = useState(false);
  const [formError, showError] = useState(false);

  // const jumpRef = useRef();
  const reRef = useRef();

  const onSubmit = async (data) => {
    setSubmitting(true);
    showError(false);
    const token = await reRef.current.executeAsync();
    data.token = token;
    reRef.current.reset();

    const response = await fetch('/.netlify/functions/sendGetInTouch', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      body: JSON.stringify({ data }),
    });

    setTimeout(() => {
      if (response.status !== 200) {
        setSubmitting(false);
        showError(true);
        // jumpRef.current.scrollIntoView({ behavior: 'auto', block: 'center' });
      } else {
        setSubmitting(false);
        showSuccess(true);
        reset();
        // jumpRef.current.scrollIntoView({ behavior: 'auto', block: 'center' });
      }
    }, 1500);
  };

  return (
    <section className="aq-feature-bg pt-8 pb-8 bg-grey">
      <div className="container-fluid">
        <div className="component-wrap">
          <div className="row">
            <div className="col-lg-6 pb-4 pb-lg-0">
              <h1 className="h1-two-line-v2 purple mb-15 mt-6">
                <span>Get in touch</span>
              </h1>
              <h3>
                We would love to know your opinion on AquaSmart, please complete
                your details below
              </h3>
              <form className="pt-5" onSubmit={handleSubmit(onSubmit)}>
                <fieldset>
                  {/* <div className="form-group">
                    <label htmlFor="exampleInputPassword1" className="sr-only">
                      Name
                    </label>
                    <input
                      type="name"
                      className={`form-control ${
                        errors.contactName ? 'is-invalid' : ''
                      }`}
                      aria-invalid={errors.contactName ? 'true' : 'false'}
                      placeholder="Name*"
                      required=""
                      {...register('contactName')}
                    />
                    {errors.contactName && (
                      <p className="text-danger font-weight-bold" role="alert">
                        {errors.contactName?.message}
                      </p>
                    )}
                    {!errors.contactName && !formSuccess && <br />}
                  </div> */}
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1" className="sr-only">
                      Email address
                    </label>
                    <input
                      type="email"
                      className={`form-control ${
                        errors.contactEmail ? 'is-invalid' : ''
                      }`}
                      placeholder="Enter email address*"
                      required=""
                      {...register('contactEmail')}
                    />
                    {errors.contactEmail && (
                      <p className="text-danger font-weight-bold" role="alert">
                        {errors.contactEmail?.message}
                      </p>
                    )}
                    {!errors.contactEmail && !formSuccess && <br />}
                  </div>
                  {/* <div className="form-group">
                    <label htmlFor="exampleSelect1" className="sr-only">
                      Topic
                    </label>
                    <div className="select-wrapper">
                      <select
                        className={`form-control ${
                          errors.contactTopic ? 'is-invalid' : ''
                        }`}
                        style={{ appearance: 'none' }}
                        aria-invalid={errors.contactTopic ? 'true' : 'false'}
                        required=""
                        defaultValue=""
                        {...register('contactTopic')}
                      >
                        <option default disabled value="">
                          Select topic*
                        </option>
                        <option value="Feedback - your opinion matters">
                          Feedback - your opinion matters
                        </option>
                        <option value="Other">Other</option>
                      </select>
                      {errors.contactTopic && (
                        <p
                          className="text-danger font-weight-bold"
                          role="alert"
                        >
                          {errors.contactTopic?.message}
                        </p>
                      )}
                      {!errors.contactTopic && !formSuccess && <br />}
                    </div>
                  </div> */}
                  <div className="form-group">
                    <label htmlFor="exampleTextarea" className="sr-only">
                      Example textarea
                    </label>
                    <textarea
                      className={`form-control ${
                        errors.contactMessage ? 'is-invalid' : ''
                      }`}
                      rows="3"
                      placeholder="Type your message here..."
                      {...register('contactMessage')}
                    />
                    {errors.contactMessage && (
                      <p className="text-danger font-weight-bold" role="alert">
                        {errors.contactMessage?.message}
                      </p>
                    )}
                    {!errors.contactMessage && !formSuccess && <br />}
                    {formSuccess && !isDirty && (
                      <p className="form-text text-purple pb-2">
                        Great, we’ll keep you updated!
                      </p>
                    )}
                  </div>
                  <button type="submit" className="btn btn-light-blue">
                    Submit
                  </button>
                  <br />
                </fieldset>
              </form>
              {/* ReCAPTCHA */}
              <ReCAPTCHA
                sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                size="invisible"
                ref={reRef}
              />
            </div>
            {submitting && <EmailLoader />}
            <div className="col-lg-6">
              <img
                src="/images/Characters/Kai-Face-Grey-BG.svg"
                className="w-100"
                alt="Aquanaut Kai"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetInTouch;
