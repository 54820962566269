import React from 'react';

import BroughtToYouBy from '../components/BroughtToYouBy';
import GetInTouch from '../components/GetInTouch';
import Layout from '../components/layout';
import Seo from '../components/Seo';

const Contact = ({ location }) => (
  <Layout page="contact" location={location}>
    <Seo
      manualTags={{
        seoTitle: 'Contact',
        seoDescription:
          'Please share your opinions on AquaSmart we would love to here from you',
      }}
    />
    <GetInTouch />
    <BroughtToYouBy />
  </Layout>
);

export default Contact;
