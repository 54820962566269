import React from 'react';

const BroughtToYouBy = () => (
  <section className="aq-feature-bg py-10 bg-light-blue">
    <div className="container-fluid">
      <div className="component-wrap">
        <div className="row">
          <div className="col-lg-6 pb-4 pb-lg-0 d-flex align-items-center">
            <div>
              <h3>
                The AquaSmart programme and the Aquanauts are brought to you by
                South East Water.
              </h3>
              <p>
                For more ways to save water and to get involved click the learn
                more button below.
              </p>
              <a
                className="btn btn-blue me-4 mb-3 mt-4"
                href="https://www.southeastwater.co.uk/help/save-water/"
                target="_blank"
                rel="noreferrer"
              >
                Learn more
              </a>
            </div>
          </div>
          <div className="col-lg-6 d-flex justify-content-center align-content-center mt-5 mt-lg-0 px-10 px-lg-0">
            <img
              src="/images/General/sew-logo-new.svg"
              alt="South East Water logo"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default BroughtToYouBy;
